<template>
   <CRow>
        <CCol col="12" xl="6">
            <div id="aq_box" >
            <img :src="aqi_good" alt="날씨" style="max-width: 50%; height: 50%;">
            <h4> 초미세먼지(PM2.5)</h4>
            <p> 16 ㎍/㎥(1h)</p>
            <p> 23 ㎍/㎥(24h)</p>  
            </div>
        </CCol>
        <CCol col="12" xl="6">
            <div id="aq_box" >
            <img :src="aqi_bad" alt="날씨" style="max-width: 50%; height: 50%;">
            <h4> 미세먼지(PM10) </h4>
            <p> 232 ㎍/㎥(1h)</p>
            <p> 226 ㎍/㎥(24h)</p>  
            </div>
        </CCol>
    </CRow>
</template>


<script>
    import good from '@/assets/icons/codeg/icon_good.png'
    import normal from '@/assets/icons/codeg/icon_normal.png'
    import bad from '@/assets/icons/codeg/icon_bad.png'

export default{
    name: 'AirQuality',
    data () {
        return {
        aqi_good: good,
        aqi_bad: bad, 
        aqi_normal: normal,
        }
    },

}




</script>

<style> 
#codeg_container4 h3{
    margin-top: 3%;
    text-align: center;
}

#aq_box{
    text-align: center;
}

</style>