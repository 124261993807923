<template>

  <div class ="black_bg" style="box-sizing:border-box"> 
    <div class="white_bg">
      <div class="container">
        <!-- header contents -->
        <div class="row">
          <div class="col-lg" id="setting_contents">
            <h3 class="contents_headline"> 급수 </h3>
          </div>
          <div class="col-lg" id="setting_contents">
            <h3 class="contents_headline"> 조명 </h3>

          </div>
        </div>
        <div class="row"><button @click="closeModal"> 뒤로가기  </button></div>
      </div>
    </div>
  </div>

</template>

<script>

export default{

  name:"Settings",
  props: ['closeModal'],
  data(){
    return{
    } 
  },
  methods:{
    closeSettingsModal() {
      this.closeModal();
    }
  }

}
</script>


<style>
.black_bg{
  width: 100%;
  height: 100%;
  z-index:1;
  position: fixed;
  padding: 20px;
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.white_bg{
  width: 100%;
  height: 100%;
  background-color: rgb(188, 188, 188);
  border-radius: 10px;
  padding: 20px;

}

#setting_contents{
  background-color: rgba(255, 255, 255, 0.708);
    height: 650px;
    overflow: hidden;
    margin: 2%;
    border-radius: 10px;
}

.contents_headline{
  margin: 4%;
  text-align: center;
}

</style>