<template>
    <div>
        <GoogleMap
            :sensor_type="8"
            :sensor_level="1"
            ref="googleMap"
            style="
            height: 100%;
            padding-top: 2%;
            padding-bottom: 2%;
            padding-left: 2%;
            padding-right: 2%;
            "
        />
    </div>
</template>


<script>
import GoogleMap from "@/views/codeg/GoogleMap";

export default{
    name: 'Location',
    data () {
        return {

        }
    },
    components:{
        GoogleMap
    }

}


</script>