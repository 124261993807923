<template>
  <div class="container">
  <!-- header contents -->
  <div class="row">
    <div class="col-lg" id="header_col">
      <div class="sk_logo_box">
        <a href="http://www.tech9.co.kr">
          <img src="@/assets/icons/sk_logo.png" style="width:120px;object-fit:cover;"/>
        </a>
      </div>
    </div>
    <div class="col-lg" id="header_col"> 
      <!-- @click="openModal" -->
        <div id="current_time_box">{{ formattedDateTime }} <button class="setting_btn"> <img class="setting_icon" @click="openModal" src="@/assets/icons/codeg/icon_settings_bw.png"/> </button> </div>  
        <!-- <img src="@/assets/icons/codeg/icon_settings_bw.png" style="width:40px;object-fit:cover;"/>  -->
    </div>
  </div>

  <!-- top contents -->
  <div class="row">
    <div class="col-lg" id="top_contents">
      <CCard id="codeg_container4">
         
        <CRow>
            <CCol col="12" xl="12">
                <h3 id="codeg_headline"> 대기질 현황</h3>
            </CCol>
            <CCol col="12" xl="12">
              <AirQuality ref="AirQuality"/>
            </CCol>
        </CRow>
        </CCard>
    </div>
    <div class="col-lg" id="top_contents">
      <CCard id="codeg_container4">
        <CRow>
            <CCol col="12" xl="12">
                <h3 id="codeg_headline"> 일기예보</h3>
            </CCol>
            <CCol col="12" xl="12">
              <Weather ref="weatherView"/>
          </CCol> 
        </CRow>
      </CCard>
    </div>
    <div class="col-lg" id="top_contents">
      <CCard id="codeg_container4">
        <CRow>
          <CCol col="12" xl="12">
              <h3 id="codeg_headline"> 현위치</h3>
          </CCol>
          <CCol col="12" xl="12">
          <Location />
        </CCol>
        </CRow>
      </CCard>
    </div>
  </div>

  <!-- bottom contents area  -->
  <div class="row">
    <div class="col-lg" id="bottom_contents">
      <CCard id="codeg_container12">
        <CRow>
          <CCol col="12" xl="12">
              <h3 id="codeg_headline"> 그래프</h3>
          </CCol>
          <CCol col="12" xl="12">
        </CCol>
        </CRow>
      </CCard>
    </div>
  </div>
  <div v-if="isModalOpen==true" >
    <Settings :closeModal="closeModal" />
  </div>
  
</div>
</template>


<script>
import Weather from "@/views/codeg/Weather";
import AirQuality from "@/views/codeg/AirQuality.vue";
import Location from "@/views/codeg/Location.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import Moment from "moment";
import Settings from "@/views/codeg/Settings.vue";

export default {
  name: 'CODEGBoard',
  extends: Dashboard,
  components: { 
    Weather,
    AirQuality,
    Location,
    Settings
  }, 
  data(){
    return {
      formattedDateTime : '',
      isModalOpen: false
    }
  },
  mounted(){
    const currentDate = Moment();
    const hours = currentDate.hours();
    const period = hours < 12 ? '오전' : '오후';
    this.formattedDateTime = currentDate.format(`YYYY년 MM월 DD일 ${period} hh시 mm분`);
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
      
    },
    closeModal() {
      this.isModalOpen = false;
    }
  }
   
};
</script>

<style scoped>


  @font-face {
      font-family: 'Tenada';
      src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2210-2@1.0/Tenada.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  
  * { 
    font-family: 'Tenada';
  }

  #codeg_container4 {
      background-color: rgba(255, 255, 255, 0.808);
      height: 90%;
      max-height: 440px;
      overflow: hidden;
  }
  #codeg_container12{
    background-color: rgba(255, 255, 255, 0.808);
    height: 380px;
    
  }

  #header_col{
    margin-bottom: 1.5%;
  
  }
  #top_contents{
  max-height: 330px;
  }
  #bottom_contents{
    max-height: 500px;
  }

  #current_time_box{
    text-align: right;
    font-size: 1.4em;
    color: white;
  }

  .setting_btn{
    background-color: transparent;
    /* border: none;
    cursor: pointer; */
    padding: 0;
  }

  .setting_icon{
    width:36px;
    
  }

  #codeg_container4 h3{
    margin-top: 6%;
    margin-bottom: 4%
  }

.modal{
  background-color: black;
}
</style>