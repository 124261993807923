<template>
    <div id="weather-contents">
        <CCol col="12" xl="4">
            <div id="weather_box">
            <div id="weather_img_box">
            <img :src="weather_image01" alt="날씨" style="max-width: 50%; height: 50%;" id="weather_img">
            </div>
            <h4> 1시간 후 </h4>
            <p> 기온 : 20 ℃</p>
            <p> 강수확률: 22%</p>  
            </div>
        </CCol>
        <CCol col="12" xl="4">
            <div id="weather_box">
            <div id="weather_img_box">
                <img :src="weather_image02" alt="날씨" style="max-width: 50%; height: 50%;" id="weather_img">
            </div>
            <h4> 2시간 후 </h4>
            <p> 기온 : 20 ℃</p>
            <p> 강수확률: 22%</p>  
            </div>  
        </CCol>
        <CCol col="12" xl="4">
            <div id="weather_box">
            <div id="weather_img_box">    
            <img :src="weather_image03" alt="날씨" id="weather_img">
            </div>
            <h4> 3시간 후 </h4>
            <p> 기온 : 20 ℃</p>
            <p> 강수확률: 22%</p>
            </div>  
        </CCol>
    </div>
</template>
    
<script>
    import loopback from '@/services/loopback';
    import moment from 'moment';
    import utils from '@/services/utils';
    import clear from '@/assets/icons/codeg/icon_clear.png'
    import cloudy from '@/assets/icons/codeg/icon_cloudy.png'
    import night from '@/assets/icons/codeg/icon_night.png'
import { CRow } from '@coreui/vue';
    
    export default {
    name: 'Weather',
    components: {
    CRow
},
    props: {
        prefix: {
        type: String,
        default: ''
        }
    },
    created: function() {
        this.userInfo = this.$store.state.auth.user_info;
        this.user = this.userInfo.user;
        this.sites = this.userInfo.sites
        this.active_site_index = _.get(this.user, "active_site_index", 0);
        this.getWeatherData(this.active_site_index, this.user.active_site_name);
    },
    data () {
        return {
        active_site_index: 0,
        siteWeather: {},
        weather_image01: clear,
        weather_image02: cloudy,
        weather_image03: night,
        address_ : ""
        }
    },
    methods: {
        getWeatherData(index, address) {
        var self = this;
        var default_site_guid = _.get(this.user, "default_site_guid");
        this.address_ = address;
        var data = {
            from: moment(new Date().setMinutes(0, 0, 0)).subtract(1,'day'),
            to: moment(new Date().setMinutes(0, 0, 0)),
            site_guid: _.isEmpty(this.user.active_site_guids[index]) ? default_site_guid : this.user.active_site_guids[index]        
        }
        return loopback
            .method('weather', 'getWeatherStat', data)
            .then(res => {
            if(res.data.length == 0){
                //console.log("load default data: ", data);
            }
            else{
                res.data[0].VEC = utils.getWindDirectionName(Number(res.data[0].VEC))
                self.siteWeather = res.data[0];
                self.siteWeather.ncstTime = self.parseFcstTime(self.siteWeather.ncstTime);
                self.weather_image = this.getWeatherImage(self.siteWeather.PTY);
                console.log(self.weather_image)
            }
            return Promise.resolve(self.siteWeather)
            })
        }, 
        getWeatherImage(pty) {

        var image_ = '@/assets/icons/codeg/icon_cloudy.png';

        switch(pty){
            case '0':
            image_ = 'images/sun.png';
            break;
            case '1':
            image_ = 'images/rainy.png';
            break;
            case '2':
            image_ = 'images/cloudy_rain.png';
            break;
            case '3':
            image_ = 'images/snowy.png';
            break;
            case '4':
            image_ = 'images/rainy.png';
            break;
            case '5':
            image_ = 'images/hail.png';
            break;
            case '6':
            image_ = 'images/snowy.png';
            break;
            case '7':
            image_ = 'images/snowy.png';
            break;                                          
        }      
        return image_;
        }
    }
    }
    </script>

    <style scoped>
    
    #weather-contents{
        display:flex;
    }

    #weather_box{
        text-align: center;
    }

    #weather_img{ 
        max-width: 50%; 
        height: 50%;
    }

    #weather_img_box{
        min-height: 63px;
    }
    </style>